import * as React from 'react'
import Layout from 'components/Layout/Layout'
import Seo from 'components/Layout/Seo'
import { FeaturesTeaserSlice, PricingSlice } from 'components/Slices'

const PricingPage = () => {
  const pageName = 'Pricing'
  const breadcrumbList = [{ position: 1, name: pageName, path: 'pricing' }]

  return (
    <Layout>
      <Seo title="Pricing" pathname="pricing" breadcrumbList={breadcrumbList} />
      <PricingSlice />
      <FeaturesTeaserSlice />
    </Layout>
  )
}

export default PricingPage
